//network/baseUrl.js

// ================================================================设置网络请求根路径
let baseUrl = (function() {
	// 请求根路径
	let urlStr = '';
	if (process.env.NODE_ENV === 'development') {
		urlStr = 'https://cwsq.qqbbpp.com';
		// urlStr = 'https://api.cwqbb.com';
		console.log('开发环境');
	} else {
		urlStr = 'https://api.cwqbb.com';
		console.log('生产环境');
	}
	return urlStr
})();

export default {
	baseUrl
}