<template>
  <div class="home">
    <div class="top-title"></div>
    <div class="header">
      <div class="title">
        出物社区ID
      </div>
      <div class="charge-input">
        <div class="text-box">
          <input class="input-box" placeholder="请输入您的用户ID" v-model="Uid" @input="textChange" type="text"
            oninput="if(value.length>6)value=value.slice(0,6)">
        </div>
        <div class="user" v-if="userShow">
          <div class="user-img">
            <img :src="userInfo.headimgurl" alt="">
          </div>
          <div class="user-info">
            <div class="user-name">
              {{ userInfo.name }}
            </div>
            <div class="user-id">
              (ID: {{ userInfo.id }})
            </div>
          </div>
        </div>
        <div class="user" v-if="messageShow">
          <div class="user-info">
            <div class="user-name">
              {{ message }}
            </div>

          </div>
        </div>
      </div>
      <div class="charge-money">
        选择充值金额
      </div>
      <div class="charge-list">
        <div class="charge-item" v-for="(item, index) in dataList" :key="index" @click="changeMoney(index)"
          :class="moneyIndex == index ? 'selected' : ''">
          <div class="aidou"><img src="../assets/aidou.png" alt="">
            <div class="count">{{ item.bean_for_android }}</div>
          </div>
          <div class="money">￥{{ item.money }}</div>
        </div>


      </div>
    </div>
    <div class="footer">
      <div class="recharge-title">
        充值方式
      </div>
      <div class="method-list">
        <div class="method-item" v-for="(item, index) in methodList" :key="index" @click="changeMethod(index)">
          <div class="item-left">
            <div class="method-img"><img :src="item.img" alt=""></div>
            <div class="method-name">{{ item.name }}</div>
          </div>

          <div class="dui"><img src="../assets/dui.png" alt="" v-if="methodIndex == index"></div>
        </div>

      </div>
      <div>

      </div>
    </div>
    <div class="bottom-box">
      <input class="radio-box" type="checkbox" v-model="checkBoxStatus" @click="changeStatus">
      <div>充值服务协议</div>
    </div>
    <el-dialog title="确认充值" :visible.sync="rechargeShow" width="80%" center v-if="dataList.length">
      <div class="dialog-body">
        <img class="head-img" :src="userInfo.headimgurl" alt="">
        <div class="dialog-uname">{{ userInfo.name }}</div>
        <div class="dialog-uid">ID:{{ userInfo.id }}</div>
        <div class="dialog-money">金额：<span class="money">{{ dataList[moneyIndex].money }}</span>元</div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="rechargeShow = false">取 消</el-button>
        <el-button type="primary" @click="recharge">确 定</el-button>
      </span>
    </el-dialog>
    <div v-html="zfbHtml" style="height:40px">
    </div>

  </div>
</template>

<script>

export default {
  name: 'HomeView',
  components: {
  },
  data() {
    return {
      Uid: '',
      dataList: [],
      moneyIndex: 0,
      userShow: false,
      messageShow: false,
      userInfo: {
      },
      methodList: [{
        name: "支付宝",
        img: require('../assets/zhifubao.png')
      }
      ],
      methodIndex: null,
      checkBoxStatus: true,
      rechargeShow: false,
      message: '',
      flag: false,
      zfbHtml: ''
    }
  },
  created() {
    this.getRechargeList()
  },
  methods: {
    textChange() {
      const pattern = /^[1-9]\d{0,5}$/; // 以1-9开头，后面跟0到5位数字


      if (!pattern.test(this.Uid)) {
        // 如果输入的最后一个字符不满足条件，阻止输入该字符
        this.Uid = this.Uid.slice(0, -1);
      }
      if (this.Uid.length < 6) {
        this.userShow = false
        this.messageShow = false
      } else {
        this.searchUser(this.Uid)
        console.log(this.Uid);
      }
      // this.searchUser(this.Uid)



    },
    changeMoney(index) {
      this.moneyIndex = index
      console.log(this.dataList[index].money)

    },
    changeMethod(index) {
      //判断用户id是否正确
      if (!this.flag) {
        this.$message({ message: '请输入正确的用户id再付款!', type: 'warning' });
        return
      }
      //判断协议框是否勾选
      if (this.checkBoxStatus) {
        this.methodIndex = index
        this.rechargeShow = true
        if (index == 0) {
          this.getZfbLink()
        }
      } else {
        this.$message({ message: '请勾选服务协议!', type: 'warning' });
      }


    },
    changeStatus() {
      this.checkBoxStatus = !this.checkBoxStatus
    },
    //
    recharge() {
      if (this.methodIndex == 0) {
        this.getZfbLink()
        this.rechargeShow = false
        document.forms[0].submit();
      } else {
        console.log('微信支付');
      }
    },
    //用户查询
    searchUser(id) {
      this.$api.findUser(id).then(res => {
        //搜索框不为空时
        if (res.code == 200) {
          //未搜索到用户
          if (res.data === null) {
            this.message = res.message
            this.messageShow = true
            this.flag = false
            //搜索到用户
          } else {
            this.userInfo = res.data
            this.userShow = true
            this.flag = true
          }
          //搜索框为空时
        } else {
          this.flag = false
        }
      })
    },
    //获取充值套餐列表
    getRechargeList() {
      this.$api.rechargeList().then(res => {
        this.dataList = res.data
      })
    },
    //获取支付宝跳转链接
    getZfbLink() {
      let params = {
        money: this.dataList[this.moneyIndex].money,
        id: this.Uid
      }
      this.$api.zhifubao(params).then(res => {
        console.log(res)
        // return
        this.zfbHtml = res
      })
    }
  }

}
</script>
<style lang="less" scoped>
.home {
  background-color: #101010;
  color: #fff;
  height: 100%;

  .top-title {
    font-size: 14px;
    height: 20px;
    line-height: 60px;
    text-align: center;
  }

  .header {

    .title {
      font-size: 14px;
      margin-bottom: 4px;
      margin-left: 6px;
    }

    .charge-input {
      display: flex;
      margin-bottom: 20px;

      .text-box {
        width: 50%;
        margin-right: 9px;
        margin-left: 4px;

        .input-box {
          width: 100%;
          height: 35px;
          background: #262626;
          border-radius: 4px 4px 4px 4px;
          color: #909399;
          border: none;
        }
      }

      .user {
        display: flex;
        align-items: center;

        .user-img {

          img {

            width: 38px;
            height: 38px;
            border-radius: 50%;
            margin-right: 6px;
          }
        }

        .user-info {
          font-size: 14px;
        }
      }
    }

    .charge-money {
      font-size: 14px;
      margin-left: 8px;
      margin-bottom: 14px;
    }

    .charge-list {
      padding: 0 10px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .charge-item {
        background: #262626;
        height: 65px;
        list-style: none;
        width: 31%;
        border-radius: 6px;
        margin-bottom: 16px;
        position: relative;

        .aidou {
          margin-top: 10px;
          font-size: 18px;
          height: 26px;
          line-height: 26px;
          align-items: center;
          display: flex;
          justify-content: center;

          img {
            height: 20px;
            width: 20px;
          }

        }

        .money {
          text-align: center;
          font-size: 10px;
        }


      }

      .selected {
        border: 1px solid #ebebeb;
      }
    }
  }

  .footer {
    .recharge-title {
      height: 20px;
      margin-left: 12px;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 6px;
    }

    .method-list {
      height: 84px;
      padding: 0 8px;
      box-sizing: border-box;
      margin: auto;

      .method-item {
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .item-left {

          display: flex;
          align-items: center;

          .method-img {
            margin: 0 12px 0 10px;

            img {
              height: 24px;
              width: 24px;
            }
          }

          .method-name {
            font-size: 12px;
          }
        }



        .dui {
          margin-right: 10px;

          img {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }

  .bottom-box {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #0D3DF2;
    font-size: 10px;

    .radio-box {
      height: 10px;
      width: 10px;
    }
  }

  /deep/.el-dialog {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin-top: 0 !important;
  }
}

.dialog {
  width: 100%;
  height: 100%;
  background: #fff;
  position: absolute;
  z-index: 1;
  left: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
  text-align: center;


}

.head-img {
  width: 60px;
  height: 60px;
  border-radius: 50%;

}


.dialog-body {
  text-align: center;

  .dialog-uname {
    font-size: 16px;
  }

  .dialog-uid {
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 18px;
  }

  .dialog-money {
    font-size: 16px;

    .money {
      font-size: 18px;
      font-weight: 600;
      color: #000;
    }
  }
}



input:focus {
  outline: none;
}
</style>
