import axios from 'axios';
import base from './baseUrl'


export default class Requeat{
  async http(params){
    let url = params.url;
    if(url == undefined){
      url = ''
    }
    let method = params.method;
    let data = params.data;
    let header = {};
    let requestUrl = base.baseUrl + url;
    if (method) {
      method = method.toUpperCase(); //小写改为大写
      if (method == "POST") {
          header['content-type'] = "application/x-www-form-urlencoded"
      } else {
          header['content-type'] = "application/json;charset=UTF-8"
      }
  }
  let instance = axios.create({
    timeout: 20000,
    headers:header
  })
  instance.interceptors.response.use(
    (res) => {
      return res.data;
    },
    (err) => {
      console.log('出错了');
      if (err.response) {
        // 如果有响应，说明请求已经发送到服务器，但可能有其他问题
        return Promise.reject(err.response.data);
      // } else if (err.request) {
      //   // 如果没有响应，但有请求，说明请求尚未发送，可能是网络问题
      //   return Promise.reject('网络问题');
      // } else {
      //   // 否则，可能是其他问题
      //   return Promise.reject('An unknown error occurred');
      }
    }
  );
  

  return new Promise((resolve, reject) => {
    instance.request({url:requestUrl,method:method,data:data})
      .then(res => {
            resolve(res)
      })
      .catch(err => {
            reject(err)
      })
  })
  }
}