import Request from "./request";

let http = new Request().http

function post(url,data){
  return http({method:'post',url:url,data:data});
}
function get(url, data){
  return http({method:'get', url:url, data:data})
}


let api = {
    //搜索用户
    findUser: id=>post('/api/users/search', {id:id}),
    rechargeList:()=> get('/api/h5/recharges'),
    zhifubao:(params)=>post('/api/h5/alipay', params)
}

export default api